import React from 'react';
import image1 from '../assets/CABLEADO1.jpeg';
import image2 from '../assets/ENLACES1.jpg';
import image3 from '../assets/CAMARAS1.jpeg';
import image4 from '../assets/ACCESO1.jpg';
import image5 from '../assets/HUMO.jpg';
import image6 from '../assets/TIERRAS1.jpg';
import sec1 from '../assets/ACCESO1.jpg';
import sec2 from '../assets/ACCESO2.jpg';
import sec3 from '../assets/ACCESO3.jpg';
import sec4 from '../assets/ACCESO4.jpg';
import sec5 from '../assets/sec5.avif';
import sec6 from '../assets/sec6.avif';
import camaras1 from '../assets/CAMARAS1.jpeg';
import camaras2 from '../assets/CAMARAS2.jpg';
import camaras3 from '../assets/camaras3.png';
import camaras4 from '../assets/CAMARAS4.jpg';
import camaras5 from '../assets/CAMARAS5.jpg';
import camaras6 from '../assets/CAMARAS6.jpg';
import camaras7 from '../assets/CAMARAS10.jpg';
import camaras8 from '../assets/CAMARAS9.jpg';
import CABLEADO from '../assets/cableado.jpg';
import CABLEADO2 from '../assets/CABLEADO2.jpeg';
import CABLEADO3 from '../assets/CABLEADO3.jpeg';
import CABLEADO4 from '../assets/CABLEADO4.jpg';
import CABLEADO5 from '../assets/CABLEADO5.jpg';
import CABLEADO6 from '../assets/CABLEADO6.jpg';
import CABLEADO7 from '../assets/CABLEADO9.jpg';
import CABLEADO8 from '../assets/CABLEADO11.jpg';
import ENLACES2 from '../assets/ENLACES2.jpg';
import ENLACES3 from '../assets/ENLACES3.jpg';
import ENLACES4 from '../assets/ENLACES4.jpg';
import ENLACES1 from '../assets/ENLACES1.jpg';
import ENLACES5 from '../assets/ENLACES5.jpeg';
import ENLACES6 from '../assets/ENLACES6.jpg';
import ENLACES7 from '../assets/ENLACES8.jpeg';
import ENLACES9 from '../assets/ENLACES9.jpeg';
import HUMO1 from '../assets/HUMO1.jpg';
import HUMO2 from '../assets/HUMO2.jpg';
import HUMO3 from '../assets/HUMO3.jpg';
import HUMO7 from '../assets/HUMO7.jpg';
import HUMO4 from '../assets/HUMO4.jpeg';
import HUMO5 from '../assets/HUMO5.jpeg';
import HUMO6 from '../assets/HUMO6.jpeg';
import HUMO8 from '../assets/incendios.jpeg';
import TIERRAS1 from '../assets/TIERRAS1.jpg';
import TIERRAS2 from '../assets/TIERRAS2.jpg';
import TIERRAS3 from '../assets/TIERRAS3.jpg';
import TIERRAS4 from '../assets/TIERRAS4.jpg';
import TIERRAS5 from '../assets/TIERRAS5.jpg';
import TIERRAS6 from '../assets/TIERRAS.jpg';
import TIERRAS7 from '../assets/TIERRAS20.jpeg';
import TIERRAS8 from '../assets/TIERRAS21.jpeg';
import CAMARAS10 from '../assets/CAMARA10.png';
import CAMARAS11 from '../assets/CAMARA11.png';

const images = [
  { src: image1, alt: "Imagen 1", section: "section1", title: "Cableado Estructurado" },
  { src: image2, alt: "Imagen 2", section: "section2", title: "Enlaces Inalámbricos" },
  { src: image3, alt: "Imagen 3", section: "section3", title: "Cámaras de Seguridad" },
  { src: image4, alt: "Imagen 4", section: "section4", title: "Control de Acceso" },
  { src: image5, alt: "Imagen 5", section: "section5", title: "Detección de Humo" },
  { src: image6, alt: "Imagen 6", section: "section6", title: "Tierras Físicas" },
];

const ImageButtons = () => {
  const handleScroll = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div style={styles.buttonRow}>
      {images.map((image, index) => (
        <div key={index} style={styles.buttonContainer}>
          <img
            src={image.src}
            alt={image.alt}
            style={styles.buttonImage}
            onClick={() => handleScroll(image.section)}
          />
          <span style={styles.buttonTitle}>{image.title}</span>
        </div>
      ))}
    </div>
  );
};


const image = [
  { section: 'section1', src: CABLEADO, alt: 'Cableado estructurado 1' },
  { section: 'section1', src: CABLEADO2, alt: 'Cableado estructurado 2' },
  { section: 'section1', src: CABLEADO3, alt: 'Cableado estructurado 2' },
  { section: 'section1', src: CABLEADO4, alt: 'Cableado estructurado 2' },
  { section: 'section1', src: CABLEADO5, alt: 'Cableado estructurado 2' },
  { section: 'section1', src: CABLEADO6, alt: 'Cableado estructurado 2' },
  { section: 'section1', src: CABLEADO7, alt: 'Cableado estructurado 2' },
  { section: 'section1', src: CABLEADO8, alt: 'Cableado estructurado 2' },
  { section: 'section2', src: ENLACES2, alt: 'Enlace inalámbrico 1' },
  { section: 'section2', src: ENLACES3, alt: 'Enlace inalámbrico 2' },
  { section: 'section2', src: ENLACES4, alt: 'Enlace inalámbrico 2' },
  { section: 'section2', src: ENLACES5, alt: 'Enlace inalámbrico 2' },
  { section: 'section2', src: ENLACES6, alt: 'Enlace inalámbrico 2' },
  { section: 'section2', src: ENLACES7, alt: 'Enlace inalámbrico 2' },
  { section: 'section2', src: ENLACES1, alt: 'Enlace inalámbrico 2' },
  { section: 'section2', src: ENLACES9, alt: 'Enlace inalámbrico 2' },
  { section: 'section3', src: camaras2, alt: 'Cámara de seguridad 1' },
  { section: 'section3', src: camaras1, alt: 'Cámara de seguridad 1' },
  { section: 'section3', src: camaras3, alt: 'Cámara de seguridad 1' },
  { section: 'section3', src: camaras4, alt: 'Cámara de seguridad 1' },
  { section: 'section3', src: camaras5, alt: 'Cámara de seguridad 1' },
  { section: 'section3', src: camaras6, alt: 'Cámara de seguridad 1' },
  { section: 'section3', src: camaras7, alt: 'Cámara de seguridad 1' },
  { section: 'section3', src: camaras8, alt: 'Cámara de seguridad 1' },
  { section: 'section4', src: sec1, alt: 'Control de acceso 1' },
  { section: 'section4', src: sec2, alt: 'Control de acceso 1' },
  { section: 'section4', src: sec3, alt: 'Control de acceso 1' },
  { section: 'section4', src: sec4, alt: 'Control de acceso 1' },
  { section: 'section4', src: sec5, alt: 'Control de acceso 1' },
  { section: 'section4', src: sec6, alt: 'Control de acceso 1' },
  { section: 'section4', src: CAMARAS10, alt: 'Control de acceso 1' },
  { section: 'section4', src: CAMARAS11, alt: 'Control de acceso 1' },
  { section: 'section5', src: HUMO2, alt: 'Detector de humo 1' },
  { section: 'section5', src: HUMO3, alt: 'Detector de humo 1' },
  { section: 'section5', src: HUMO4, alt: 'Detector de humo 1' },
  { section: 'section5', src: HUMO5, alt: 'Detector de humo 1' },
  { section: 'section5', src: HUMO7, alt: 'Detector de humo 1' },
  { section: 'section5', src: HUMO6, alt: 'Detector de humo 1' },
  { section: 'section5', src: HUMO1, alt: 'Detector de humo 1' },
  { section: 'section5', src: HUMO8, alt: 'Detector de humo 1' },
  { section: 'section6', src: TIERRAS2, alt: 'Tierra física 1' },
  { section: 'section6', src: TIERRAS3, alt: 'Tierra física 1' },
  { section: 'section6', src: TIERRAS4, alt: 'Tierra física 1' },
  { section: 'section6', src: TIERRAS1, alt: 'Tierra física 1' },
  { section: 'section6', src: TIERRAS5, alt: 'Tierra física 1' },
  { section: 'section6', src: TIERRAS6, alt: 'Tierra física 1' },
  { section: 'section6', src: TIERRAS7, alt: 'Tierra física 1' },
  { section: 'section6', src: TIERRAS8, alt: 'Tierra física 1' },
 
 
 
];


const ProjectSection = () => {
  const sections = [
    { id: 'section1', title: 'Cableado Estructurado' },
    { id: 'section2', title: 'Enlaces Inalámbricos' },
    { id: 'section3', title: 'Cámaras de Seguridad' },
    { id: 'section4', title: 'Control de Acceso' },
    { id: 'section5', title: 'Detección  de Humo' },
    { id: 'section6', title: 'Tierras Físicas' },
  ];

  return (
    <section id="proyect" style={styles.projectSection}>
      {sections.map((section, index) => (
        <div key={index} id={section.id} style={styles.section}>
          <h2>{section.title}</h2>
          <div style={styles.imageGrid}>
            {/* Aquí se pueden agregar hasta 12 imágenes por sección */}
            {image.filter(img => img.section === section.id).map((image, idx) => (
              <img
                key={idx}
                src={image.src}
                alt={image.alt}
                style={styles.sectionImage}
              />
            ))}
          </div>
        </div>
      ))}
    </section>
  );
};



const styles = {
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: '20px 0',
    flexWrap: 'wrap',
  },
  buttonContainer: {
    textAlign: 'center',
    margin: '10px',
  },
  buttonImage: {
    cursor: 'pointer',
    width: '120px',
    height: '120px',
    objectFit: 'cover',
    boxShadow: '0 8px 16px rgba(128, 0, 128, 0.5)', // Sombra más visible
    transition: 'box-shadow 0.3s ease', // Transición suave para el hover
  },
  buttonTitle: {
    display: 'block',
    marginTop: '5px',
    fontSize: '14px',
    color: '#4a148c', // Color del título
  },
  projectSection: {
    marginTop: '50px',
    backgroundColor: '#f3e5f5', // Fondo morado tenue
    padding: '20px',
  },
  section: {
    marginBottom: '40px', // Asegúrate de que no haya duplicado
  },
  imageGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))', // Ajusta el tamaño automáticamente
    gap: '20px', // Espacio entre las imágenes
  },
  sectionImage: {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(128, 0, 128, 0.3)',
    // Eliminamos el marginLeft ya que puede causar problemas en el diseño de la cuadrícula
  },

};



const App = () => {
  return (
    <div>
      <ImageButtons />
      <ProjectSection />
    </div>
  );
};

export default App;
