import React, { useState } from 'react';


import './Navbar.css'; // Archivo de estilos para la barra de navegación
import './Marcas.css';
import'./proyec.css';
import './SolucionesCarousel.css';
import EmptyContainers from './EmptyContainers';
import logo from '../assets/logo.png'; // Logo de la barra de navegación // Nueva imagen para mostrar debajo de la barra de navegación
import 'bootstrap/dist/css/bootstrap.min.css';
import './ButtonStyles.css'; // Archivo de estilos para los botones
import CarouselComponent from './CarouselComponent'; // Importar el componente del carrusel
import SolucionesCarousel from './SolucionesCarousel'; // Componente del carrusel de soluciones
import Marcas from './Marcas';

import marcas from '../assets/MARCAS.png';
import Proyectos from './Proyectos';
import cert60 from '../assets/world.png';
import cert61 from '../assets/engagement.png';
import cert62 from '../assets/analytics.png';
import image1 from '../assets/mision.jpeg';


const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState('home');

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const handleSectionClick = (section) => {
    setSelectedSection(section);
    setMenuOpen(false); // Cierra el menú al seleccionar una sección
  };

  return (
    <>
      <nav className="navbar" id="home">
        <h1 className="navbar-brand"></h1>
        <img src={logo} alt="Logo" className="logo" />
        <button className="menu-toggle" onClick={toggleMenu}>
          &#9776;
        </button>
        <ul className={`navbar-links ${menuOpen ? 'open' : ''}`}>
          <li><a href="#home" onClick={() => handleSectionClick('home')}>Inicio</a></li>
          <li><a href="#soluc" onClick={() => handleSectionClick('soluc')}>Soluciones</a></li>
          <li><a href="#marc" onClick={() => handleSectionClick('marc')}>Marcas</a></li>
          <li><a href="#proyect" onClick={() => handleSectionClick('proyect')}>Proyectos</a></li>
          <li><a href="#nosotros" onClick={() => handleSectionClick('nosotros')}>Quiénes somos</a></li>
          <li><a href="#contact" onClick={() => handleSectionClick('contact')}>Contacto</a></li>
        </ul>
      </nav>

      {selectedSection === 'home' && <CarouselComponent />}
      {selectedSection === 'home' && <EmptyContainers />}
      {selectedSection === 'home' && <Marcas />}
      
      {selectedSection === 'soluc' && (
        <section id="soluc">
          <SolucionesCarousel /> {/* Carrusel de soluciones */}
        </section>
      )}

     
   
{selectedSection === 'marc' && (
  <section id="marc">
    

   
    <div className="banner-container">
      <img src={marcas} alt="Banner de Marcas" className="banner-image" />
      
     
    </div>
    
    
  </section>
)}
{selectedSection === 'proyect' && <Proyectos/>}
{selectedSection === 'nosotros' && (
  <section id="nosotros">
    

    <div className="banner-container">
      <img src={image1} alt="Banner de Marcas" className="banner-image" />
      
      
    </div>
    
    
  </section>
)}
      {selectedSection === 'contact' && (
  <div id="contact-wrapper">
    <section id="contact">
      <h2>Contacto</h2>
      <p>Estamos aquí para ayudarte. ¡Contáctanos!</p>
      <div className="contact-container">
        <div className="contact-item">
          <img src={cert60} alt="Email" className="contact-icon" />
          <div>
            <h3>Email</h3>
            <p>administracion@grupocetys.com.mx</p>
          </div>
        </div>
        <div className="contact-item">
          <img src={cert62} alt="Dirección" className="contact-icon" />
          <div>
            <h3>Dirección</h3>
            <p>Salamanca, Guanajuato.</p>
          </div>
        </div>
        <div className="contact-item">
          <img src={cert61} alt="Redes Sociales" className="contact-icon" />
          <div>
            <h3>Redes Sociales</h3>
            <p>
              <a href="https://www.facebook.com/GrupoCETYS?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">Facebook</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
)}


    </>
  );
};

export default Navbar;