import React from 'react';
import certification1 from '../assets/PANDUIT.png';
import certification2 from '../assets/PELCO.png';
import certification3 from '../assets/ALAVARION.jpg';
import certification4 from '../assets/RAD.jpeg';
import certification5 from '../assets/INFINET.png';
import certification6 from '../assets/HIKVISION.png';
import certification7 from '../assets/AJHUA.png';
import certification8 from '../assets/key.png';
import certification9 from '../assets/firelite.png';
import certification10 from '../assets/totalground.png';
import certification11 from '../assets/edwards.png';
import certification12 from '../assets/optronics.jpg';
import './Marcas.css';  

const Marcas = () => {
    // Lista de imágenes
    const certifications = [
        certification1,
        certification2,
        certification3,
        certification4,
        certification5,
        certification6,
        certification7,
        certification8,
        certification9,
        certification10,
        certification11,
        certification12,
    ];

    return (
        <section className="full-width-container">
            <div className="certificaciones-content">
                <h3 className="certificaciones-title">Certificaciones</h3>
                <div className="certificaciones-container">
                    {certifications.map((imageSrc, index) => (
                        <div key={index} className="certificacion-image">
                            <img src={imageSrc} alt={`Certificación ${index + 1}`} />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Marcas;
