// src/App.js
import React from 'react';
import Navbar from './components/Navbar';


function App() {
  return (
    <div className="App">
      <Navbar />
      <header className="App-header">
       
      </header>
    </div>
  );
}
export default App;
