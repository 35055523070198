import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CarouselComponent.css'; // Importa el archivo CSS

/*import image2 from '../assets/tarjeta correo.jpg';*/
import image3 from '../assets/mision.jpeg';
import image30 from '../assets/GRUPOCETYS.jpeg';
import image31 from '../assets/CamarasdeSeguridad-ai.jpeg';
import image32 from '../assets/cableadoEstrutcurado-ai.jpeg';
import image33 from '../assets/DetectorHumo-ai.jpeg';
import image34 from '../assets/TIERRASFISICAS (1).jpeg';
import image35 from '../assets/TELECOMUNICACIONES.jpeg';
import image36 from '../assets/SoporteTecnico.jpeg';
import image37 from '../assets/ControlAccesos-ai.jpeg';

const CarouselComponent = () => {
  return (
    <Carousel className="carousel" interval={null}>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image30}
          alt="Second slide"
        />
        <Carousel.Caption>
          {/* Tu contenido aquí */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image31}
          alt="Fourth slide"
        />
        
        </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image37}
          alt="Fourth slide"
        />
        
        <Carousel.Caption>
          {/* Tu contenido aquí */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image32}
          alt="Fifth slide"
        />
        <Carousel.Caption>
          {/* Tu contenido aquí */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image33}
          alt="Sixth slide"
        />
        <Carousel.Caption>
          {/* Tu contenido aquí */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image34}
          alt="Seventh slide"
        />
        <Carousel.Caption>
          {/* Tu contenido aquí */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image35}
          alt="Eighth slide"
        />
        <Carousel.Caption>
          {/* Tu contenido aquí */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image36}
          alt="Eighth slide"
        />
        <Carousel.Caption>
          {/* Tu contenido aquí */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image3}
          alt="Ninth slide"
        />
        <Carousel.Caption>
          {/* Tu contenido aquí */}
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselComponent;
