import React, { useState } from 'react';
import { Carousel, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SolucionesCarousel.css'; // Archivo CSS específico para este carrusel

// Importa las imágenes del carrusel de soluciones
import image31 from '../assets/CamarasdeSeguridad-ai.jpeg';
import image32 from '../assets/cableadoEstrutcurado-ai.jpeg';
import image33 from '../assets/DetectorHumo-ai.jpeg';
import image34 from '../assets/TIERRASFISICAS (1).jpeg';
import image35 from '../assets/SoporteTecnico.jpeg';
import image36 from '../assets/ControlAccesos-ai.jpeg';
import image37 from '../assets/TELECOMUNICACIONES.jpeg';

const SolucionesCarousel = () => {
  const [index, setIndex] = useState(0);

  // Cambia el índice cuando se hace clic en un botón
  const goToSlide = (slideIndex) => {
    setIndex(slideIndex);
  };

  return (
    <div className="soluciones-container">
      {/* Botones de navegación */}
      <div className="buttons-container">
        <Button variant="primary" onClick={() => goToSlide(0)}>Cámaras de Seguridad</Button>
        <Button variant="primary" onClick={() => goToSlide(1)}>Cableado Estructurado</Button>
        <Button variant="primary" onClick={() => goToSlide(2)}>Detección de Humo</Button>
        <Button variant="primary" onClick={() => goToSlide(3)}>Tierras Físicas</Button>
        <Button variant="primary" onClick={() => goToSlide(4)}>Soporte Técnico</Button>
        <Button variant="primary" onClick={() => goToSlide(5)}>Control de Accesos</Button>
        <Button variant="primary" onClick={() => goToSlide(6)}>Enlaces de Telecomunicaciones</Button>
      </div>

      {/* Carrusel de soluciones */}
      <Carousel 
        activeIndex={index} 
        onSelect={() => {}}  // Deshabilita la selección automática
        controls={false}     // Deshabilita los controles de navegación por defecto
        indicators={false}   // Deshabilita los indicadores
        interval={null}      // Desactiva el auto-desplazamiento
        className="soluciones-carousel"
      >
        <Carousel.Item>
          <img className="d-block w-100" src={image31} alt="Cámaras de Seguridad" />
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={image32} alt="Cableado Estructurado" />
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={image33} alt="Detector de Humo" />
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={image34} alt="Tierras Físicas" />
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={image35} alt="Soporte Técnico" />
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={image36} alt="Control de Accesos" />
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={image37} alt="Enlaces de Telecomunicaciones" />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default SolucionesCarousel;
